<template>
  <div class="dashboard-cal-event" :class="getCssClass">
    <div class="dashboard-cal-event__time">
      <div class="dashboard-cal-event__month">{{ event.eventDate | datetime('MMM dd') }}</div>
      <div class="dashboard-cal-event__hour">{{ event.eventDate | datetime('p') }} {{ event.timeZoneCode }}</div>
    </div>

    <div class="dashboard-cal-event__info">
      <a class="dashboard-cal-event__title">{{ event.type }}</a>
      <div class="dashboard-cal-event__excerpt">
        <router-link :to="'/project/info/' + event.projectId">
          {{ event.title }}
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "dashboard-member-event",
  props: {
    event: { type: Object, required: true }
  },
  computed: {
    getCssClass () {
      return this.event.type.toLowerCase().replace(' ', '-');
    },
  }
};
</script>
